.sidebar-search {
  display: flex;

  input[type="search"] {
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid $gray;
    flex-shrink: 1;
    max-width: calc(100% - 3rem);
    flex-basis: calc(100% - 3rem);
    -webkit-appearance: none;
  }
  
  input[type="submit"] {
    flex-basis: 3rem;
    flex-shrink: 0;
    border: 0;
    background-color: $gray;
    padding: 0.8px;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-image: url('./src/img/search.svg');
    background-size: 1.8rem;
    background-repeat: no-repeat;
    background-position: center;
  }

}

input[type="search"] {
  font-size: 1.6rem;
  padding: 0.5rem;
}

input[type="submit"] {
  display: inline-block;
  font-size: 1.6rem;
}