.box {
  border: 1px solid $gray;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 4px 4px 8px rgba(black, 0.1);

  header {
    background-color: $brand;
    color: #fff;
    padding: 1rem;
    position: relative;
    left: calc(-2rem - 1px);
    top: calc(-2rem - 1px);
    width: calc(100% + 2rem + 2px);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(black, 0.4);
  }

}