.site-header {
  background-color: #ffffff;
  box-shadow: 0px 10px 35px rgba(black, 0.2);
  border-bottom: 1px solid $gray;
  font-size: 1.6rem;
  z-index: 1;
  position: sticky;
  top: 0;

  .site-width {
    @include phone {
      width: 100%;
      margin: 0;
    }
  }

  .logo {
    width: 100%;
    max-width: 185px;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 0 0 0 2rem;
  }

  .site-header-nav-items {
    list-style-type: none;
    display: flex;
    align-items: center;

    @include phone {
      border-top: 1px solid $gray;
      z-index: 1;
      position: absolute;
      top: 6rem;
      background-color: #fff;
      left: 0;
      right: 0;
      flex-direction: column;
      transform: translateY(-100%);
      transition: all 250ms ease-out;
      z-index: -1;

      &.is-active {
        transform: translateY(0);
        // z-index: 1;
      }

    }

    li {
      @include phone {
        width: 100%;
      }
    }

    .icon-name {
      display: none;
      @include phone {
        display: inline-block;
      }
    }

    .icon-img {
      display: none;
      @include phone {
        display: inline-block;
        order: -1;
        margin-right: 1.5rem;
      }
    }

    .icon a {
      padding-left: 1rem;
      padding-right: 1rem;
      
      @include phone {
        display: flex;
        padding-left: 3rem;
        padding-right: 3rem;
        .icon-name {
          order: 1;
          margin-left: 1.5rem;
        }
      }

    }
    
    a {
      padding: 2.5rem 2rem;
      color: $black;
      text-decoration: none;
      @include phone {
        padding: 2rem 3rem;
        border-bottom: 1px solid $gray;
        display: flex;
      }

      &:hover {
        background-color: $lgray;
      }

      &:hover path {
        fill: $brand;
      }
      
      svg {
        display: block;
        max-height: 23px;
        width: 100%;
        max-width: 23px;
        
        path {
          fill: #777;
        }

      }

    }

  }

  a {
    display: block;
    padding: 1rem 0;
  }

  button {
    display: none;
    @include phone {
      display: block;
    }
  }

}