$brand: green;
$lgray: #f0f0f0;
$gray: #ccc;
$black: #555;

@mixin phone {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@import 'src/scss/reset';

@import './node_modules/hamburgers/dist/hamburgers.min.css';

@import 'src/scss/grid';

@import 'src/scss/modules/header';
@import 'src/scss/modules/hero';
@import 'src/scss/modules/box';
@import 'src/scss/modules/product-listing';
@import 'src/scss/modules/form';