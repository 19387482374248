.product-listing {
  padding: 5rem 0;
  font-size: 1.6rem;
  
  aside .box {
    position: sticky;
    top: 12rem;
    @include phone {
      position: static;
      top: auto;
    }
  }

}

.products {
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: grid;
  grid-gap: 3rem;
  
  .product {
    border-bottom: 1px solid $gray;
    padding-bottom: 3rem;
    &:first-child {
      border-top: 1px solid $gray;
      padding-top: 3rem;
    }
  }

}

.product-list-item {
  display: grid;
  grid-gap: 0 2rem;
  grid-template-columns: 150px 1fr;
  @include phone {
    grid-template-columns: 75px 1fr;
  }

  > div:nth-child(2),
  .product-info,
  .product-controls {
    grid-column: 2 / 3;
    @include phone {
      grid-column: 2 / 4;
    }
  }

  .product-controls {
    grid-row: 1 / 3;
    grid-column: 3 / 3;
    align-self: center;
    @include phone {
      margin-top: 2rem;
      grid-row: 3 / 4;
      grid-column: 2 / 3;
      // display: grid;
      // grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .product-image {
    grid-row: 1/3;
    
    a {
      display: block;
    }

    img {
      display: block;
      width: 100%;
      max-width: 150px;
    }
  }

  svg {
    max-width: 25px;
  }

  .product-name a {
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    color: $brand;
    font-size: 2.4rem;
  }

  .product-pricing-availability {
    text-align: right;
    margin-bottom: 1rem;
    @include phone {
      text-align: left;
    }
  }

  .nobr {
    display: flex;
    align-items: center;
  }

  .product-add-quantity {
    font-size: 1em;
    vertical-align: top;
    height: calc(2em + 1px);
    border-radius: .25em 0 0 .25em;
    border: 1px solid $gray;
    margin: 0 0.5rem 0 0;
    box-sizing: border-box;
    display: inline-block;
  }

}

.hs-product-content {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);

  .hs-sort-orders {
    direction: ltr;
    margin-left: auto;
    font-size: 1.4rem;
    border-radius: 5px;
    border: 1px solid $gray;
    display: inline-block;
    background-color: transparent;
    padding: 1rem 3.5rem 1rem 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('./src/img/select.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 97% 8px;
    @include phone {
      grid-column: 1 / 3;
      width: 100%;
    }
  }
  
  .products,
  .paging {
    grid-column: 1 / 3;
  }

  .paging {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    
    .hs-btn {
      text-align: right;
      display: inline-block;
      max-width: 5rem;
      text-decoration: none;
      padding: 1rem 2rem;
      margin-left: auto;
    }

  }

}

.hs-listing-count {
  align-self: center;
  font-weight: bold;
  font-size: 1.8rem;
  text-transform: uppercase;
  @include phone {
    grid-column: 1 / 3;
  }
}

.hs-btn {
  background-color: $brand;
  display: block;
  text-align: center;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;   
}