.site-width {
  max-width: 1080px;
  width: calc(100% - 12rem);
  margin-left: auto;
  margin-right: auto;
  
  @include phone {
    width: calc(100% - 4rem);
  }

}

.grid {
  display: grid;
  grid-gap: 5rem;
}

.grid--two-thirds {
  grid-template-columns: 250px 1fr;

  @include phone {
    grid-template-columns: 1fr;    
  }

}