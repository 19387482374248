* {
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: 'allumi-std', Helvetica, Arial, sans-serif;
  font-weight: 200;
  font-size: 10px;
  line-height: 1.4;
  min-height: 100vh;
  background-color: #fff;
  color: $black;
}