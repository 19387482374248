.hero {
  background-color: $brand;
  background-image: linear-gradient(rgba($brand, 0.75), rgba($brand, 0.75)), url('./src/img/hero.jpg');
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 8rem 0;
  text-align: center;

  h1 {
    text-transform: uppercase;
    font-size: 4.5rem;
    position: relative;
    display: inline-block;
    text-shadow: 2px 2px 5px rgba(black, 0.3);

    @include phone {
      font-size: 3.5rem;
      line-height: 1.1;
      margin-bottom: 5px;
    }

    .price-tag {
      font-size: 2rem;
      position: absolute;
      top: -1.5rem;
      right: calc(100% - 2rem);
      transform: rotate(-25deg);
      background-color: #fff;
      color: $brand;
      display: block;
      padding: 0.25rem 0.75rem 0.25rem 2.5rem;
      text-shadow: none;
      
      @include phone {
        position: static;
        transform: rotate(0);
        display: block;
        max-width: 5rem;
        margin: 0 auto 1rem;
      }
      
      &:after {
        content: '';
        background-color: $brand;
        position: absolute;
        top: 50%;
        left: 0.75rem;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: translateY(-50%);
      }

    }

  }

  h2 {
    font-weight: normal;
    font-size: 3rem;
    text-shadow: 2px 2px 5px rgba(black, 0.3);
  
    @include phone {
      font-size: 2.25rem;
    }

  }

}